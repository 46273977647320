import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './DifficultyMeter.module.scss';

const map = {
    1: 'yellow',
    2: 'orange',
    3: 'red',
};

export default function DifficultyMeter({ level, style = {} }) {
    return (
        <div className={styles.container} style={style}>
            {[1, 2, 3].map((meter, i) => {
                const colorStyle = styles[map[level]];
                const klass = classnames(styles.block, {
                    [colorStyle]: i + 1 <= level,
                });

                return <span className={klass} key={i} />;
            })}
        </div>
    );
}

DifficultyMeter.propTypes = {
    style: PropTypes.object,
    level: PropTypes.oneOf([1, 2, 3]),
};
