import React from 'react';
import PropTypes from 'prop-types';

import LeftChevron from '~images/icons/chevron-left.svg';
import RightChevron from '~images/icons/chevron-right.svg';

import styles from './RadioGroup.module.scss';

export default function RadioGroup({
    radios,
    name,
    label,
    onChange,
    onPrevClick,
    onNextClick,
}) {
    const activeValue = radios.find((radio) => radio.checked).text;

    return (
        <div className={styles.container}>
            <p className={styles.label}>{label}</p>
            <div className={styles.optionContainer}>
                <span className={styles.activeValue}>{activeValue}</span>
                {radios.map((radio, i) => {
                    return (
                        <span className={styles.option} key={i}>
                            <input
                                type="radio"
                                name={name}
                                value={radio.value}
                                checked={radio.checked}
                                onChange={onChange}
                            />
                        </span>
                    );
                })}
                <div className={styles.pickerButtons}>
                    <button
                        onClick={onPrevClick}
                        data-name={name}
                        type="button"
                    >
                        <LeftChevron />
                    </button>
                    <button
                        onClick={onNextClick}
                        data-name={name}
                        type="button"
                    >
                        <RightChevron />
                    </button>
                </div>
            </div>
        </div>
    );
}

RadioGroup.propTypes = {
    radios: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
