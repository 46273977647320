import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ArrowLeft from '~images/icons/arrow-left.svg';

import styles from './BackArrow.module.scss';

export default function BackArrow({ path, title }) {
    return (
        <Link to={path} className={styles.arrow}>
            <ArrowLeft />
            <span className={styles.title}>{title}</span>
        </Link>
    );
}

BackArrow.propTypes = {
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
