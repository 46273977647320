import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import DifficultyMeter from '~components/DifficultyMeter';

import styles from './WeaponCategoryButton.module.scss';

export default function WeaponCategoryButton({ slug, name, difficulty }) {
    const meterStyle = {
        position: 'absolute',
        bottom: 12,
        right: 12,
        maxWidth: 60,
    };

    return (
        <Link to={slug} className={styles.button}>
            {name}
            {difficulty && (
                <DifficultyMeter level={difficulty} style={meterStyle} />
            )}
        </Link>
    );
}

WeaponCategoryButton.propTypes = {
    slug: PropTypes.string,
    difficulty: PropTypes.number,
    name: PropTypes.string,
};
