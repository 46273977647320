import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { siteMetadata } from '~config/site';

export default function WeaponCategoryMeta({ weaponCategory }) {
    const title = `${weaponCategory.name} | ${siteMetadata.title}`;
    const schema = JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': `${siteMetadata.siteUrl}${weaponCategory.slug}`,
                    name: weaponCategory.name,
                },
            },
        ],
    });

    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <script type="application/ld+json">{schema}</script>
        </Helmet>
    );
}

WeaponCategoryMeta.propTypes = {
    weaponCategory: PropTypes.object,
};
