import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';

import WeaponCategoryMeta from '~templates/meta/WeaponCategoryMeta';

import { siteMetadata } from '~config/site';
import Container from '~components/Container';
import BackArrow from '~components/BackArrow';
import Modal from '~components/modal/Modal';
import CogIcon from '~images/icons/cog.svg';
import { updateSetting } from '~redux/ducks/settings';
import TipList from '~components/TipList';
import RadioGroup from '~components/form/RadioGroup';
import WeaponCategoryButton from '~components/WeaponCategoryButton';
import NonHome from '~templates/NonHome';

import styles from './WeaponCategory.module.scss';

export default function WeaponCategory(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const weaponCategory = get(props, 'data.allWeaponCategory.nodes[0]');
    const weaponCategoryName = get(
        props,
        'data.allWeaponCategory.nodes[0].name'
    );
    const tips = get(props, 'data.allWeaponCategory.nodes[0].tips');
    const weapons = get(props, 'data.allWeaponCategory.nodes', []).reduce(
        (acc, category) => {
            acc = acc.concat(category.weapons);
            return acc;
        },
        []
    );
    const dispatch = useDispatch();
    const [settings] = useSelector((state) => {
        return [state.settings];
    });
    const damascusOnlyOptions = [
        { text: 'Yes', value: 'yes', checked: settings.showDasmascusGunsOnly },
        { text: 'No', value: 'no', checked: !settings.showDasmascusGunsOnly },
    ];

    function handleSettingsClick() {
        openModal();
    }

    function handleSettingChange(e) {
        const input = e.currentTarget;
        const name = input.name;
        let value = input.value;

        switch (input.type) {
            case 'checkbox':
                value = input.checked;
                break;

            case 'radio': {
                // Fudge yes no radio buttons
                if (['yes', 'no'].includes(value)) {
                    value = input.checked && value === 'yes';
                }
                break;
            }

            default:
                break;
        }

        dispatch(updateSetting(name, value));
    }

    function handlePrevSettingChange(e) {
        const button = e.currentTarget;
        const name = button.getAttribute('data-name');

        if (name === 'showDasmascusGunsOnly') {
            if (!settings.showDasmascusGunsOnly) {
                dispatch(updateSetting(name, true));
            }
        }
    }

    function handleNextSettingChange(e) {
        const button = e.currentTarget;
        const name = button.getAttribute('data-name');

        if (name === 'showDasmascusGunsOnly') {
            if (settings.showDasmascusGunsOnly) {
                dispatch(updateSetting(name, false));
            }
        }
    }

    // const siteDomain = get(
    //     this.props,
    //     'data.site.siteMetadata.siteUrl',
    //     ''
    // );
    // const postUrl = `${siteDomain}${get(
    //     this,
    //     'props.pageContext.slug',
    //     ''
    // )}`;
    function renderTips() {
        let content;

        if (tips && tips.length) {
            content = <TipList tips={tips} />;
        } else {
            content = (
                <p>
                    No tips yet for the {weaponCategoryName} class. Know of
                    something useful? Hit me up{' '}
                    <a
                        href={`https://twitter.com/${siteMetadata.twitterHandle}`}
                    >
                        @{siteMetadata.twitterHandle}
                    </a>
                </p>
            );
        }

        return (
            <Fragment>
                <h3>{weaponCategoryName} General Tips</h3>
                {content}
            </Fragment>
        );
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function renderSettingsModal() {
        return (
            <Modal
                title="Settings"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{ width: 'calc(100% - 32px)', maxWidth: 900 }}
            >
                <form style={{ width: '100%' }}>
                    <RadioGroup
                        label="Show only weapons required for Damascus"
                        radios={damascusOnlyOptions}
                        name="showDasmascusGunsOnly"
                        onChange={handleSettingChange}
                        onPrevClick={handlePrevSettingChange}
                        onNextClick={handleNextSettingChange}
                    />
                </form>

                <button onClick={closeModal}>
                    <span>Close</span>
                </button>
            </Modal>
        );
    }

    return (
        <NonHome location={props.location}>
            <WeaponCategoryMeta weaponCategory={weaponCategory} />
            <Container>
                <header className={styles.header}>
                    <h1>
                        <BackArrow path="/" title="Back to all guns" />{' '}
                        {weaponCategoryName}
                    </h1>
                    <button
                        className={styles.settingsButton}
                        onClick={handleSettingsClick}
                    >
                        <CogIcon />
                        <span style={{ position: 'absolute', left: -9999 }}>
                            Settings
                        </span>
                    </button>
                </header>
                {renderTips()}
                <h3>Choose a weapon</h3>
                <div className={styles.container}>
                    {weapons
                        .filter((weapon) => {
                            if (settings.showDasmascusGunsOnly) {
                                return weapon.requiredForDamascus;
                            }

                            return true;
                        })
                        .map((weapon, i) => {
                            return <WeaponCategoryButton key={i} {...weapon} />;
                        })}
                </div>
            </Container>
            {modalIsOpen && renderSettingsModal()}
        </NonHome>
    );
}

WeaponCategory.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export const pageQuery = graphql`
    query WeaponCategoryPageQuery($name: String) {
        site {
            siteMetadata {
                title
                author
                siteUrl
            }
        }
        allWeaponCategory(filter: { name: { eq: $name } }) {
            nodes {
                id
                name
                type
                slug
                tips {
                    text
                }
                weapons {
                    name
                    requiredForDamascus
                    slug
                    difficulty
                    camos {
                        levelUnlocked
                        description
                        targetAmounts
                    }
                }
                camos {
                    levelUnlocked
                    description
                    targetAmounts
                }
            }
        }
    }
`;
