// import React from 'react';
import PropTypes from 'prop-types';

export default function NonHomeLayout({ children }) {
    return children;
}

NonHomeLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    location: PropTypes.object,
};
